import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {
  Button,
  Divider,
  Form,
  message,
  Popover,
  Table,
  Tag,
  Upload,
  Modal,
} from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import {Navigate, useLocation} from "react-router";
import {
  AreaField,
  DateField,
  SearchField,
  Section,
  TextField,
  NumberField,
} from "components";
import {formatCurrency} from "utils/helpers";
import {
  useBusinessUnits,
  useProduct,
  useSuppliers,
} from "repositories";
import { useAuth, usePagination } from "hooks";
import Row from "components/Grid/Row";
import Col from "components/Grid/Col";
import { UploadWrapper, ColFormat, RowFormat, Paragraph, Title, MessageContainer} from "./styles";
import moment from "moment";
import {
  supplierStatus,
  SupplierStatus,
  SupplierType,
} from "../../../../utils/constants";
import NormalizedCurrencyField from "../../../../components/Form/NormalizedCurrencyField";

const { Dragger } = Upload;

const FormRequest = ({
  productsLinesSelected,
  project,
  selectDataForm,
  nextStep,
  prevStep,
  handleProviderType,
  currentDataForm,
  loading,
}: any) => {
  const columnsLines: any = [
    {
      title: "Id da linha TOTVS",
      dataIndex: "idLinha",
    },
    {
      title: "Tipo do Fat.",
      dataIndex: "paymentType",
    },
    {
      title: "Produto",
      dataIndex: "productDescription",
    },
    {
      // oq é ?
      title: "Categoria",
      dataIndex: "categoria",
    },
    {
      title: "Escopo",
      dataIndex: "technicalScope",
      key: "technicalScope",
      render: (_: any, record: any) => {
        return (
          <Popover
            content={record.technicalScopeDescription}
            overlayStyle={{ maxWidth: "800px" }}
          >
            {record.technicalScope}
          </Popover>
        );
      },
    },
    {
      title: "Quant",
      dataIndex: "amount",
    },
    {
      title: "Diárias",
      dataIndex: "daily",
    },
    {
      title: "Custo Unítario",
      dataIndex: "unitCost",
    },
    {
      title: "Custo total",
      dataIndex: "totalCost",
      render: (_: any, record: any) => {
        return formatCurrency(record.totalCost);
      },
    },
    {
      title: "Já tem requisições?",
      dataIndex: "withoutRequest",
      align: "center",
      render: (_: any, record: any) => {
        return record.withoutRequest ? (
          <Tag
            color="green"
            style={{ cursor: "pointer" }}
            onClick={() => openRequests(record.projectRequestItems)}
          >
            Sim
          </Tag>
        ) : (
          <Tag color="default">Não</Tag>
        );
      },
    },
    {
      title: "Requisições",
      dataIndex: "approvalRequests",
    },
    {
      title: "Saldo da linha",
      dataIndex: "lineBalance",
    }
  ];
  const columnsLinesEditable: any = [
    {
      title: "Id da linha TOTVS",
      dataIndex: "idLinha",
    },
    {
      title: "Tipo do Fat.",
      dataIndex: "paymentType",
    },
    {
      title: "Produto",
      dataIndex: "productDescription",
    },
    {
      title: "Categoria",
      dataIndex: "categoria",
    },
    {
      title: "Escopo",
      dataIndex: "technicalScope",
      key: "technicalScope",
      render: (_: any, record: any) => {
        return (
            <Popover
                content={record.technicalScopeDescription}
                overlayStyle={{ maxWidth: "800px" }}
            >
              {record.technicalScope}
            </Popover>
        );
      },
    },
    {
      title: "Quant",
      dataIndex: "amount",
      render: (_: any, record: any) => {
        const editable = isEditingAmount(record);
        return editable ? (
            <Form form={form}>
              <Form.Item name={`amount-${record.idLinha}`} initialValue={record.amount}>
                <TextField name={`amount-${record.idLinha}`} label="" textHelp="Quant" />
              </Form.Item>
            </Form>
        ) : (
            record.amount
        );
      },
    },
    {
      title: "Diárias",
      dataIndex: "daily",
      render: (_: any, record: any) => {
        const editable = isEditingDaily(record);
        return editable ? (
            <Form form={form}>
              <Form.Item name={`daily-${record.idLinha}`} initialValue={record.daily}>
                <NumberField name={`daily-${record.idLinha}`} label="" textHelp="Dia(s)" />
              </Form.Item>
            </Form>
        ) : (
            record.daily
        );
      },
    },
    {
      title: "Custo Unítario",
      dataIndex: "unitCost",
      render: (_: any, record: any) => {
        const editable = isEditingUnitCost(record);
        return editable ? (
            <Form form={form}>
              <Form.Item name="unitCost" initialValue={record.unitCost}>
                <NormalizedCurrencyField
                    name="valor"
                    label=""
                    placeholder="0,00"
                    rules={[{ required: true, message: 'Campo obrigatório' }]}
                />
              </Form.Item>
            </Form>
        ) : (
            formatCurrency(record.unitCost)
        );
      },
    },
    {
      title: "Custo total",
      dataIndex: "totalCost",
      render: (_: any, record: any) => {
        return formatCurrency(record.amount * record.daily * record.unitCost);
      },
    },
    {
      title: "",
      dataIndex: "action",
      render: (_: any, record: any) =>
          editModeButtonColumnsLine ? (
              <Button
                  disabled={suppliers.length === 0}
                  onClick={() => {
                    setEditModeButtonColumnsLine(false);
                    handleEdit(record.key);
                  }}
              >
                Editar
              </Button>
          ) : (
              <Button
                  onClick={() => {
                    const params = {
                      amount: form.getFieldValue(`amount-${record.idLinha}`),
                      daily: form.getFieldValue(`daily-${record.idLinha}`),
                      unitCost: form.getFieldValue("unitCost"),
                    };
                    handleSaveProductLine(params);
                    setEditingKeyUnitCost(null);
                    setEditingKeyDaily(null);
                    setEditingKeyAmount(null);
                    setEditModeButtonColumnsLine(true);
                  }}
              >
                Salvar
              </Button>
          ),
    },
  ];
  function applyPhoneMaskWithNinthDigit(phoneNumber: any) {
    const onlyNumbers = phoneNumber.replace(/\D/g, "");
    let formattedNumber;
    if (onlyNumbers.length === 11) {
      formattedNumber = onlyNumbers.replace(
        /(\d{2})(\d{5})(\d{4})/,
        "($1) $2-$3"
      );
    } else if (onlyNumbers.length === 10) {
      formattedNumber = onlyNumbers.replace(
        /(\d{2})(\d{4,5})(\d{4})/,
        "($1) $2-$3"
      );
    } else {
      return phoneNumber;
    }

    return formattedNumber;
  }
  const columnsFreelancer: any = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Razão Social",
      dataIndex: "corporateName",
    },
    {
      title: "Nome fantasia ",
      dataIndex: "fantasyName",
    },
    {
      title: "Tipo de Prestador",
      dataIndex: "typeSupplier",
      render: (_: any, record: any) =>
        record.typeSupplier === SupplierType.SUPPLIER
          ? "Fornecedor"
          : "Freelancer",
    },
    {
      title: "Telefone",
      dataIndex: "telephone",
      render: (_: any, record: any) => {
        return applyPhoneMaskWithNinthDigit(record.telephone);
      },
    },
    {
      title: "E-mail",
      dataIndex: "email",
    },
    {
      title: "Status Homologação",
      dataIndex: "status",
      render: (_: any, record: any) => {
        if (record.status === SupplierStatus.APPROVED) {
          return <Tag color="green">Homologado</Tag>;
        }

        if (record.status === SupplierStatus.NOT_APPROVED) {
          return <Tag color="red">Bloqueado</Tag>;
        }

        if (record.status === SupplierStatus.PRE_REGISTRATION) {
          return <Tag color="blue">{supplierStatus[record.status]}</Tag>;
        }

        return <Tag>{supplierStatus[record.status]}</Tag>;
      },
    },
    {
      title: "Preciario",
      dataIndex: "preciary",
      align: "center",
      render: (_: any, record: any) => {
        if (record.preciary) {
          return <Tag>Sim</Tag>;
        } else {
          return <Tag>Não</Tag>;
        }
      },
    },
    {
      title: "",
      dataIndex: "action",
      render: (_: any, record: any) => (
        <Button onClick={() => onRemove()}>Excluir</Button>
      ),
    },
  ];
  const columnsSuppliers: any = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Razão Social",
      dataIndex: "corporateName",
    },
    {
      title: "Nome fantasia ",
      dataIndex: "fantasyName",
    },
    {
      title: "Tipo de Prestador",
      dataIndex: "typeSupplier",
      render: (_: any, record: any) =>
        record.typeSupplier === SupplierType.SUPPLIER
          ? "Fornecedor"
          : "Freelancer",
    },
    {
      title: "Telefone",
      dataIndex: "telephone",
      render: (_: any, record: any) => {
        return applyPhoneMaskWithNinthDigit(record.telephone);
      },
    },
    {
      title: "E-mail",
      dataIndex: "email",
    },
    {
      title: "Status Homologação",
      dataIndex: "status",
      render: (_: any, record: any) => {
        if (record.status === SupplierStatus.APPROVED) {
          return <Tag color="green">Homologado</Tag>;
        }

        if (record.status === SupplierStatus.NOT_APPROVED) {
          return <Tag color="red">Bloqueado</Tag>;
        }

        if (record.status === SupplierStatus.PRE_REGISTRATION) {
          return <Tag color="blue">{supplierStatus[record.status]}</Tag>;
        }

        return <Tag>{supplierStatus[record.status]}</Tag>;
      },
    },
    {
      title: "Preciario",
      dataIndex: "preciary",
      align: "center",
      render: (_: any, record: any) => {
        if (record.preciary) {
          return <Tag>Sim</Tag>;
        } else {
          return <Tag>Não</Tag>;
        }
      },
    },
    {
      title: "Valor negociado",
      dataIndex: "value",
      align: "center",
      render: (_: any, record: any) => (
        <span>{formatCurrency(record.value)}</span>
      ),
    },
    {
      title: "Unidade de medida",
      dataIndex: "unitOfMeasurement",
      align: "center",
    },
    {
      title: "",
      dataIndex: "action",
      render: (_: any, record: any) => (
        <Button onClick={() => onRemove()}>Excluir</Button>
      ),
    },
  ];
  const [form] = Form.useForm();
  const [suppliers, setSuppliers] = useState<any>([]);
  const [suppliersSearch, setSuppliersSearch] = useState<any>([]);
  const [productByPriceRange, setProductByPriceRange] = useState<any>([]);
  const [seniorityData, setSeniorityData] = useState<any>({});
  const [productsLines, setProductsLines] = useState<any>(
    productsLinesSelected
  );
  const [endDate, setEndDate] = useState<any>(moment());
  const [payDate, setPayDate] = useState<any>(moment());
  const [startDate, setStartDate] = useState<any>(moment());
  const [ productsLinesNotEdited, setProductsLinesNotEdited ] = useState<any>(productsLinesSelected);
  const [optionsSearchSuppliers, setOptionsSearchSuppliers] = useState<any>([]);
  const [mappedSuppliers, setMappedSuppliers] = useState<any>([]);
  const [suppliersId, setSuppliersId] = useState<any>("");
  const [recordUpdateLine, setRecordUpdateLine] = useState<any>("");
  const [businessUnit, setBusinessUnit] = useState<any>(0);
  const [editModeButtonColumnsLine, setEditModeButtonColumnsLine] = useState(true);
  const [disableDates, setDisableDates] = useState(false);
  const [provider, setProvider] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [filesItems, setFilesItems] = useState<any>([]);
  const [editingKeyAmont, setEditingKeyAmount] = useState(null);
  const [editingKeyUnitCost, setEditingKeyUnitCost] = useState(null);
  const [editingDaily, setEditingKeyDaily] = useState(null);
  const isEditingDaily = (record: any) => record.key === editingDaily;
  const isEditingUnitCost = (record: any) => record.key === editingKeyUnitCost;
  const isEditingAmount = (record: any) => record.key === editingKeyAmont;
  const [btnClick, setBtnClick] = useState("");
  const [requestModal, setRequestModal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const requestsColumns: any = [
    {
      title: "Emissão",
      dataIndex: "issueDate",
      render: (_: any, record: any) => {
        return record.issueDate
          ? moment(record.issueDate).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      title: "Vencimento",
      dataIndex: "maturity",
      render: (_: any, record: any) => {
        return record.maturity
          ? moment(record.maturity).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      title: "Produto",
      dataIndex: "productName",
    },
    {
      title: "Prestador",
      dataIndex: "typeProvider",
      render: (_: any, record: any) => {
        return record.typeProvider === "SUPPLIER" ? "Fornecedor" : "Freelancer";
      },
    },
    {
      title: "Tipo",
      dataIndex: "typeRequest",
      render: (_: any, record: any) => {
        if (record.typeRequest === "SIMPLE") return "Simples";
        return "Composta";
      },
    },
    {
      title: "Categoria",
      dataIndex: "category",
    },
    {
      title: "Alocação",
      dataIndex: "allocation",
    },
    {
      title: "Local",
      dataIndex: "buName",
    },
    {
      title: "Cliente",
      dataIndex: "client",
    },
    {
      title: "Timing",
      dataIndex: "timing",
      render: (_: any, record: any) => {
        if (record.timing === "PLANEJADA")
          return <Tag color="green">Planejada</Tag>;

        return <Tag color="red">Não planejada</Tag>;
      },
    },
    {
      title: "Fatura",
      dataIndex: "paymentType",
    },
    {
      title: "Valor da requisição",
      dataIndex: "totalCost",
      render: (_: any, record: any) => {
        return formatCurrency(record?.totalCost);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: any, record: any) => {
        switch (record.status) {
          case "NEW":
            return <Tag color="cyan">Novo</Tag>;
          case "IN_QUOTATION":
            return <Tag color="yellow">Em cotação</Tag>;
          case "APPROVED":
            return <Tag color="green">Aprovado</Tag>;
          case "REFUSED":
            return <Tag color="orange">Reprovada</Tag>;
          case "CANCELED":
            return <Tag color="red">Cancelada</Tag>;
          case "ON_APPROVAL":
            return <Tag color="yellow">Em aprovação</Tag>;
          case "PAID_OUT":
            return <Tag color="green">Pago</Tag>;
          default:
            return record.status;
        }
      },
    },
  ];

  const openRequests = (requests: any) => {
    setRequestModal(requests);
    setIsModalOpen(true);
  };

  const handleEdit = (key: React.SetStateAction<null>) => {
    setEditingKeyAmount(key);
    setEditingKeyDaily(key);
    setEditingKeyUnitCost(key);
    const record = productsLinesSelected.find(
      (item: { key: React.SetStateAction<null> }) => key === item.key
    );
    setRecordUpdateLine(record);
  };
  const normalizeValue = (value: any) => {
    value = value.trim();
    value = value.replace(/,/g, '.');
    let parts = value.split('.');
    if (parts.length > 2) {
      value = parts.slice(0, -1).join('') + '.' + parts.slice(-1);
    }
    value = value.replace(/[^\d.]/g, '');
    if (!value.includes('.')) {
      value = value.slice(0, -2) + '.' + value.slice(-2);
    }
    return parseFloat(value);
  };

  const handleSaveProductLine = async (params: any) => {
    const productId = recordUpdateLine.productId;
    const idLinhaRecordUpdateLine = recordUpdateLine.idLinha

    const formattedUnitCost = normalizeValue(params.unitCost);
    const preciaryExist = !!productByPriceRange?.preciary;
    const productT = await repositoryProduct.findProductById(productId);

    const { preciary } = productT;

    if ( Number(params.unitCost) === 0 ) {
      message.warn(
          `Valor de custo unitário informado é 0.`
      );
    }
    const updatedProductsLines = productsLines.map((productLine: any) => {
      if (productLine.idLinha === idLinhaRecordUpdateLine) {
        if (params.amount) productLine.amount = Number(params?.amount);
        if (params.daily) productLine.daily = Number(params?.daily);

        if (preciaryExist && params.unitCost && typeof formattedUnitCost !== "number") {
          if (provider === 2 && productByPriceRange.value >= parseFloat(formattedUnitCost)) {
            productLine.unitCost = formattedUnitCost;
          } else if (provider === 1 && preciary && formattedUnitCost <= seniorityData.unitMaximunPrice) {
            productLine.unitCost = formattedUnitCost;
          } else {
            if (provider === 2 && productByPriceRange.value < formattedUnitCost) {
              message.warn(`Valor de custo unitário maior que o valor negociado. Tente outro valor.`);
            }
            if (provider === 1 && preciary && !(formattedUnitCost < seniorityData.unitMaximunPrice)) {
              message.warn(`Valor acima do preço máximo definido para a senioridade. Tente novamente.`);
            }
          }
        } else {
          if (provider === 2 && preciaryExist && productByPriceRange.value >= formattedUnitCost) {
            productLine.unitCost = formattedUnitCost;
          } else if (provider === 2 && preciaryExist) {
            message.warn(`Valor de custo unitário maior que o valor negociado. Tente outro valor.`);
          }

          if (provider === 1 && preciary && formattedUnitCost <= seniorityData.unitMaximunPrice) {
            productLine.unitCost = formattedUnitCost;
          } else if (provider === 1 && preciary) {
            message.warn(`Valor de custo unitário maior que o valor negociado. Tente outro valor.`);
          }

          if (provider === 2 && !preciaryExist) {
            productLine.unitCost = formattedUnitCost;
          } else if (provider === 1 && !preciary) {
            productLine.unitCost = formattedUnitCost;
          }
        }

        if (
            provider === 1 &&
            !preciaryExist &&
            formattedUnitCost &&
            formattedUnitCost <= seniorityData.unitMaximunPrice
        ) {
          productLine.unitCost = formattedUnitCost;
        }
      }

      return productLine;
    });

    setProductsLines(updatedProductsLines);
    form.setFieldValue("valor","")
  };

  const { roles: authRoles, hasRole } = useAuth();
  const repositoryBU = useBusinessUnits();
  const repositoryProduct = useProduct();
  const repositorySupplier = useSuppliers();
  const pagination = usePagination();

  const requestForm = async () => {
    const responseProduct = await repositoryProduct.findProductById(
      productsLinesSelected[0].productId
    );
    if (!responseProduct) return;

    const paramsBU: any = {
      name: project.buName,
    };
    const responseBU = await repositoryBU.findBus(paramsBU);
    if (!responseBU) return;

    const mappedDataBU = responseBU?.content.map(
      (item: { id: any }) => item.id
    );
    setBusinessUnit(mappedDataBU);

    if (currentDataForm.providerType) {
      form.setFieldValue("providerType", currentDataForm.providerType);
      form.setFieldValue("typeSupplier", currentDataForm.providerType === 2 ? "Fornecedor" : "Freelancer");
      setForceUpdate(!forceUpdate);
    }
    if (currentDataForm.timing) { //
      form.setFieldValue(
        "timing",
        currentDataForm.timing === "PLANEJADA" ? "Planejada" : "Não planejada"
      );
    }
    if (currentDataForm.maturity !== ""){
      form.setFieldValue(
          "pay",
          currentDataForm.maturity ? moment(currentDataForm.maturity) : payDate
      );
    }
    if (currentDataForm.serviceStartDate !== ""){
      form.setFieldValue(
          "startDate",
          currentDataForm.serviceStartDate
              ? moment(currentDataForm.serviceStartDate)
              : startDate
      );
    }
    if (currentDataForm.serviceEndDate !== ""){
      form.setFieldValue(
          "endDate",
          currentDataForm.serviceEndDate
              ? moment(currentDataForm.serviceEndDate)
              : endDate
      );
    }
    if (currentDataForm.seniority !== ""){
      form.setFieldValue("seniority", currentDataForm.seniority);
    }
    form.setFieldValue("unitMinimunPrice", currentDataForm.unitMinimunPrice);
    form.setFieldValue("unitMaximunPrice", currentDataForm.unitMaximunPrice);
    if (currentDataForm.suppliers) {
      setSuppliers(currentDataForm.suppliers);
    }
    form.setFieldValue("technicalScope", currentDataForm.technicalScope);
    if (currentDataForm.files.length > 0) {
      setFilesItems(currentDataForm.files);
    }
    form.setFieldValue("objectiveFunction", currentDataForm.objectiveFunction);

    form.setFieldValue(
      "justifyIfItIsAnEmergency",
      currentDataForm.justifyIfItIsAnEmergency
    );

    form.setFieldValue(
      "JustifyReasonSingleSupplier",
      currentDataForm.JustifyReasonSingleSupplier
    );
    form.setFieldValue(
      "JustifyTheDueDateBeingShorter",
      currentDataForm.JustifyTheDueDateBeingShorter
    );
    form.setFieldValue(
      "JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate",
      currentDataForm.JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate
    );
    form.setFieldValue(
      "JustifyUnapprovedSupplier",
      currentDataForm.JustifyUnapprovedSupplier
    );
  };
  useMemo(
      () => {
        form.setFieldValue("pay", "");
        form.setFieldValue("endDate", "");
        form.setFieldValue("startDate", "");
        const productsLinesNotEditedFormated = productsLinesNotEdited.map((productLine: any) => {
        const {amount, daily, unitCost, approvalRequests, lineBalance} = productLine;
        const unitCostFormated = typeof(unitCost) === "string" ? parseFloat(unitCost.replace(/[^\d.,]/g, '')) : unitCost;

          return {
            ...productLine,
            approvalRequests: formatCurrency(approvalRequests),
            lineBalance: formatCurrency(lineBalance),
            unitCost: formatCurrency(unitCost),
            totalCost: formatCurrency(amount * daily * unitCostFormated)
          };
        });
        let productsLinesEditedFormated = []

        if (currentDataForm.items.length > 0) {
          for (let i = 0; i < productsLines.length; i++) {
            const productLine = productsLines[i];
            const { amount, daily, unitCost, approvalRequests, lineBalance } = productLine;
            const unitCostFormated =
                typeof unitCost === "string" ? parseFloat(unitCost.replace(/[^\d.,]/g, "")) : unitCost;

            productsLinesEditedFormated.push({
              ...productLine,
              approvalRequests: formatCurrency(approvalRequests),
              lineBalance: formatCurrency(lineBalance),
              unitCost: currentDataForm.items[i]?.unitCost,
              totalCost: formatCurrency(amount * daily * unitCostFormated),
              amount: currentDataForm.items[i]?.amount,
              daily: currentDataForm.items[i]?.daily,
            });
          }
          setProductsLines(productsLinesEditedFormated)
        }
        setProductsLinesNotEdited(productsLinesNotEditedFormated);
    }, []
  );
  useEffect(() => {
    if (pagination.data.current) requestForm();
  }, [
    pagination.data.current,
    pagination.data.pageSize,
    provider,
  ]);

  useEffect(() => {
    disabledSearchField(
        form.getFieldValue("startDate"),
        form.getFieldValue("endDate"),
        form.getFieldValue("pay")
    );
  }, [
    payDate,
    endDate,
    startDate
  ]);


  const onChangeStartDate = (date: moment.Moment | null) => {
    if(date === null ){
      form.setFieldValue("startDate", "");
      setStartDate("");
      return ""
    }
    const startDate = moment(date);
    setStartDate(startDate);
    form.setFieldValue("startDate", startDate);
  };
  const disabledDate = (current: any) => {
    const today = new Date(form.getFieldValue("startDate"));
    today.setHours(0, 0, 0, 0);
    return current && current < today;
  };

  const onChangeEndDate = (date: moment.Moment | null) => {
    if(date === null ){
      form.setFieldValue("endDate", "");
      setEndDate("");
      return ""
    }
    const endDate = moment(date);
    setEndDate(endDate);
    form.setFieldValue("endDate", endDate);
  };

  const onChangeIssueDate = (date: moment.Moment | null) => {
    if(date === null ){
      form.setFieldValue("pay", "");
      setPayDate("");
      return ""
    }
    const paymentDate = moment(date);
    setPayDate(paymentDate);
    form.setFieldValue("pay", paymentDate);
  };
  let initialValues = {
    client: project.clientName,
    projectId: project.idERP,
    projectName: project.projectName,
    requestLocation: project.buName,
    providerType: "",
    timing: "",
    startDate: "",
    endDate: "",
    pay: "",
    unitMaximunPrice: "",
    technicalScope: "",
    objectiveFunction: "",
    justifyIfItIsAnEmergency: "",
    JustifyReasonSingleSupplier: "",
    JustifyUnapprovedSupplier: "",
    JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate: "",
    JustifyTheDueDateBeingShorter: "",
    indicationSingleSupplierJustifiable: "",
  };

  if (authRoles && !hasRole(authRoles?.create_request_simple)) {
    return <Navigate to="/acesso-negado" />;
  }

  const props = {
    name: "file",
    multiple: true,
    action: "",
    customRequest: (file: any) => {
      setFilesItems([...filesItems, file.file]);
    },
    onDrop(e: any) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const removeFile = (file: any) => {
    const index = filesItems.findIndex((obj: any) => obj.uid === file.uid);
    const newArray = [...filesItems];
    if (index !== -1) {
      newArray.splice(index, 1);
      setFilesItems(newArray);
    }
  };
  const isValidDate = (date: any) => {
    return !isNaN(new Date(date).getTime());
  };
  const onFinish = async (values: any, btn: any = 2) => {
    console.log({btnClick})
    console.log({btn})
    if(btn === "back"){
      selectDataForm();
      prevStep();
      return;
    }
    if (!isValidDate(values.startDate) || !isValidDate(values.endDate || !isValidDate(values.pay))) {
      message.error("Preencha todas as datas corretamente");
      return;
      } else {
        const issueDateFormat = moment().format("YYYY-MM-DD");
        const serviceStartDateFormat = form.getFieldValue("startDate").format("YYYY-MM-DD");
        const payFormat = form.getFieldValue("pay").format("YYYY-MM-DD");
        const serviceEndDateFormat = form.getFieldValue("endDate").format("YYYY-MM-DD");
        const negotiationDeadline = 0
        const technicalScope = form.getFieldValue("technicalScope");
        const deliveryLocation =
            form.getFieldValue("objectiveFunction") ||
            form.getFieldValue("JustifyTheDueDateBeingShorter") ||
            form.getFieldValue(
                "JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate"
            ) ||
            form.getFieldValue("JustifyUnapprovedSupplier") ||
            form.getFieldValue("JustifyReasonSingleSupplier") ||
            form.getFieldValue("justifyIfItIsAnEmergency");
      console.log({values})
        const data = {
          issueDate: issueDateFormat,
          maturity: payFormat,
          typeProvider: suppliers[0]?.typeSupplier,
          category: "Compra & Contratação",
          allocation: "Com projeto",
          isRequestNotTiedToProject: true,
          providerType: provider,
          paymentType: "TV1",
          seniority: values.seniority,
          unitMinimunPrice: values.unitMinimunPrice,
          unitMaximunPrice: values.unitMaximunPrice,
          suppliers: suppliers,
          technicalScope: values.technicalScope,
          purchaseType: "P",
          suppliersId: suppliers.map((item: { id: any }) => Number(item.id)),
          serviceStartDate: serviceStartDateFormat,
          serviceEndDate: serviceEndDateFormat,
          approvalHierarchyId: 12,
          negotiationDeadline,
          requireThreeQuotes: false,
          timing: values.timing === "Planejada" ? "PLANEJADA" : "EMERGENCIAL",
          indicationSingleSupplierJustifiable: values.indicationSingleSupplierJustifiable !== "1",
          subsidiaryId: Number(project.subsidiaryId),
          items: productsLines.map((request: any) => ({
            productId: request.productId,
            costCenterId: request.costCenterId,
            buId: businessUnit[0],
            projectRevisionItemId: Number(request.id),
            amount: request.amount,
            daily: request.daily,
            unitCost: request.unitCost,
            unitSale: request.unitSale,
            totalSalesWithoutTax: request.amount * request.unitCost,
            deliveryLocation:
                request.JustifyTheDueDateBeingShorter ||
                request.JustifyUnapprovedSupplier ||
                request.JustifyReasonSingleSupplier ||
                request.justifyIfItIsAnEmergency ||
                request.objectiveFunction ||
                deliveryLocation,
            technicalScope,
          })),
          objectiveFunction: values.objectiveFunction,
          justifyIfItIsAnEmergency: values.justifyIfItIsAnEmergency,
          JustifyReasonSingleSupplier: values.JustifyReasonSingleSupplier,
          JustifyUnapprovedSupplier: values.JustifyUnapprovedSupplier,
          JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate:
          values.JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate,
          JustifyTheDueDateBeingShorter: values.JustifyTheDueDateBeingShorter,
          files: filesItems,
          btnStatus: btn,
        };
        if(!values.startDate && !values.endDate && values.pay) {
          message.error("Preencha todas as datas corretamente");
          return
        }

        if (data.suppliersId.length !== 0 || btn === 1) {
          switch (btnClick) {
            case "next":
              selectDataForm(data);
              nextStep();
              break;
            case "save":
              selectDataForm(data);
              break;
            default:
              selectDataForm();
              prevStep();
              break;
          }
        } else {
          message.error("Escolha um prestador");
        }
      }
  };

  const prev = (e: any) => {
    const values = form.getFieldsValue();
    onFinish(values, "back");
  };
  const onRemove = () => {
    form.setFieldValue("startDate", "");
    form.setFieldValue("endDate", "");
    form.setFieldValue("pay", "");
    form.setFieldValue("typeSupplier", "");
    setProvider(0);
    form.setFieldValue("seniority", "");
    form.setFieldValue("unitMaximunPrice", "");
    form.setFieldValue("timing", "");
    setSuppliers([]);
    setDisableDates(false);
  };

  const disabledSearchField = (startDate: any, endDate: any, pay: any) => {
    if(suppliers.length < 1){
      if (startDate !== "" && endDate !== "" && pay !== ""){
        if (startDate !== null && endDate !== null && pay !== null){
          if (startDate.isValid() && endDate.isValid() && pay.isValid()) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const onAdd = async () => {
    const params = {
      productId: productsLinesSelected[0]?.productId,
      supplierId: suppliersId,
    };
    const productByPriceRange =
      await repositorySupplier.findProductPriceRangeByFilter(params);

    if (productByPriceRange?.preciary && provider === 2) {
      const updatedProductsLines = productsLines.map((productLine: any) => {
        return {
          ...productLine,
          unitCost: productByPriceRange.value
        };
      });
      setProductsLines(updatedProductsLines);
    } else {
      const updatedProductsLines = productsLines.map((productLine: any) => {
        return {
          ...productLine,
          unitCost: 0
        };
      });
      setProductsLines(updatedProductsLines);
    }
    const indexOf = mappedSuppliers.findIndex(
      (obj: { id: string }) => String(obj.id) === String(suppliersId)
    );
    const r = await repositorySupplier.getProductPriceRange(
      mappedSuppliers[indexOf]?.id
    );

    const filtered = r.filter(
      (product: any) => product.productId === productsLinesSelected[0].productId
    );

    let mapped = mappedSuppliers[indexOf];
    mapped.preciary = filtered[0]?.preciary;
    mapped.value = filtered[0]?.value ? filtered[0]?.value : "";
    mapped.unitOfMeasurement = filtered[0]?.unitOfMeasurement
      ? filtered[0]?.unitOfMeasurement
      : "";

    setProductByPriceRange(productByPriceRange);
    setSuppliers([mapped]);
    setOptionsSearchSuppliers([]);
    form.setFieldValue("SearchFieldSuppliers", "");

    senioritySelection(mapped.seniority)

    const startDate = form.getFieldValue("startDate")
    const endDate = form.getFieldValue("endDate")
    const pay = form.getFieldValue("pay")
    const typeSupplier = mapped.typeSupplier
    const seniority = mapped.seniority ? mapped.seniority : ""

    const diffInDays = pay.diff(typeSupplier === "SUPPLIER" ? endDate : startDate, 'days');
    let timing;
    console.log({diffInDays})
    console.log({typeSupplier})
    if (startDate.isSameOrAfter(moment(), 'day')) {
      if (
          (typeSupplier === "SUPPLIER" && diffInDays >= 70) ||
          (typeSupplier === "FREELANCE" && diffInDays >= 30)
      ) {
        timing = "Planejada";
      } else if (
          (typeSupplier === "SUPPLIER" && diffInDays < 70) ||
          (typeSupplier === "FREELANCE" && diffInDays < 30)
      ) {
        timing = "Não planejada";
      }
    }else if(startDate.isBefore(moment())) {
      timing = "Não planejada";
    }

    form.setFieldValue("timing", timing)
    form.setFieldValue("typeSupplier", typeSupplier === "SUPPLIER" ? "Fornecedor" : "Freelancer");
    form.setFieldValue("providerType", typeSupplier === "SUPPLIER" ? 2 : 1);
    form.setFieldValue("seniority", seniority.charAt(0).toUpperCase() + seniority.slice(1).toLowerCase());
    setProvider(typeSupplier === "SUPPLIER" ? 2 : 1);
    handleProviderType(typeSupplier === "SUPPLIER" ? 2 : 1);
    setDisableDates(true);
  };

  const onSearchSuppliers = async (searchText: string) => {
    const productIds = productsLinesSelected.map(
      (item: { productId: number }) => item.productId
    );
    const queryParams = new URLSearchParams({
      searchAnd: searchText,
      page: "0",
      size: "10000",
    });
    productIds.forEach((id: any) => {
      queryParams.append("productIds", id.toString());
    });
    const response = await repositorySupplier.findSupplierByFilter(queryParams);
    if (!response) return;

    const mappedSuppliers = response.content.map((item) => ({
      value: item.corporateName,
      id: item.id,
    }));
    setOptionsSearchSuppliers(mappedSuppliers);
    setSuppliersSearch(response.content);
  };

  const onChangeSuppliers = (data: string) => {
    const indexOf = suppliersSearch.findIndex(
      (obj: { corporateName: string }) => obj.corporateName === data
    );
    const mappedSuppliers = suppliersSearch.map((request: any) => ({
      id: String(request.id),
      currentEvaluation:
        request.currentEvaluation !== null ? request.currentEvaluation : 0,
      currentAmountEvaluation: request.currentAmountEvaluation
        ? request.currentAmountEvaluation
        : 0,
      status: request.status,
      typeSupplier: request.typeSupplier,
      telephone: request.telephone,
      email: request.email,
      fantasyName: request.fantasyName,
      corporateName: request.corporateName,
      seniority: request.seniority,
    }));
    setMappedSuppliers([mappedSuppliers[indexOf]]);
    setSuppliersId(mappedSuppliers[indexOf].id);
  };

  const senioritySelection = async (data: any) => {
    const params: any = {
      id: productsLinesSelected[0]?.productId,
    };
    const r = await repositoryProduct.findProductByFilter(params);
    let seniorityData = {} as any;
    switch (data) {
      case "JUNIOR":
        const maxPriceJunior = Number(r?.content[0]?.maximumPriceJunior);
        form.setFieldsValue({
          unitMaximunPrice: maxPriceJunior > 0 ? formatCurrency(maxPriceJunior) : "Não há",
        });
        seniorityData.unitMaximunPrice = Number(
          r?.content[0]?.maximumPriceJunior
        );
        break;
      case "PLENO":
        const maxPricePleno = Number(r?.content[0]?.maximumPricePleno);
        form.setFieldsValue({
          unitMaximunPrice: maxPricePleno > 0 ? formatCurrency(maxPricePleno) : "Não há",
        });
        seniorityData.unitMaximunPrice = Number(
          r?.content[0]?.maximumPricePleno
        );
        break;
      case "SENIOR":
        const maxPriceSenior = Number(r?.content[0]?.maximumPriceSenior);
        form.setFieldsValue({
          unitMaximunPrice: maxPriceSenior > 0 ? formatCurrency(maxPriceSenior) : "Não há",
        });
        seniorityData.unitMaximunPrice = Number(
          r?.content[0]?.maximumPriceSenior
        );
        break;
    }
    setSeniorityData(seniorityData);
  };

  return (
    <>
      <Section>
        <div style={{ marginTop: 24 }}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={initialValues}
          >
            <>
              <Row>
                <Col>
                  <b>  Categorização, datas e gatilhos</b>
                </Col>
              </Row>
              <RowFormat>
                <ColFormat>
                  <TextField name="client" label="Cliente" disabled />
                </ColFormat>
                <ColFormat>
                  <TextField
                      name="projectName"
                      label="Nome do projeto"
                      disabled
                  />
                </ColFormat>
                <ColFormat>
                  <TextField
                      name="projectId"
                      label="ID do projeto"
                      disabled
                  />
                </ColFormat>
                <ColFormat>
                  <TextField
                    name="requestLocation"
                    label="Local da requisição"
                    disabled
                  />
                </ColFormat>
              </RowFormat>
              <Col xl={24}>
                <b>Preencha as datas do serviço</b>
              </Col>
              <MessageContainer>
                <Title>Atenção às políticas internas de prazo de pagamento de parceiros.</Title>
                <Paragraph>
                  Para fornecedores com requisição planejada, a data de vencimento deve ser de pelo menos 70 dias após o término do serviço.
                </Paragraph>
                <Paragraph>
                  Para freelancers, a data de vencimento deve ser de pelo menos 30 dias após o início do serviço.
                </Paragraph>
              </MessageContainer>
              <RowFormat>
                <ColFormat>
                  <DateField
                      name="startDate"
                      label="Data do início do serviço"
                      onChange={onChangeStartDate}
                      disabled={disableDates}
                      rules={[
                        {
                          required: true,
                          message: "Data do início do serviço obrigatório",
                        },
                      ]}
                      required
                  />
                </ColFormat>
                <ColFormat>
                  <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                          prevValues.startDate !== currentValues.startDate
                      }
                  >
                    {({getFieldValue}) => (
                        <DateField
                            name="endDate"
                            label="Data do fim do serviço"
                            disabledDate={disabledDate}
                            disabled={ disableDates ? disableDates : !getFieldValue("startDate")}
                            onChange={onChangeEndDate}
                            rules={[
                              {
                                required: true,
                                message: "Data do fim do serviço obrigatório",
                              },
                            ]}
                            required
                        />
                    )}
                  </Form.Item>
                </ColFormat>
                <ColFormat>
                  <DateField
                      name="pay"
                      label="Vencimento [Política]"
                      onChange={onChangeIssueDate}
                      disabledDate={disabledDate}
                      disabled={disableDates}
                      rules={[
                        {
                          required: true,
                          message: "Vencimento [Política] obrigatório",
                        },
                      ]}
                      required
                  />
                </ColFormat>
                <ColFormat>
                  <TextField
                    name="timing"
                    label="Timing da requisição"
                    disabled
                  />
                </ColFormat>
              </RowFormat>
              <Col>
                <Divider />
              </Col>
              <Col xl={24}>
                <b>Fornecedores ou freelancers para essa requisição</b>
              </Col>
              <br/>
              <Col xl={24} xxl={24}>
                <div style={{
                  display: "flex",
                  width: "100%",
                }}>
                  <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                          prevValues.providerType !==
                          currentValues.providerType ||
                          prevValues.senioridade !== currentValues.senioridade
                      }
                  >
                    {({getFieldValue}) => (
                        <SearchField
                            onSearch={onSearchSuppliers}
                            onChange={onChangeSuppliers}
                            required
                            name="SearchFieldSuppliers"
                            placeholder="Digite aqui o nome"
                            options={optionsSearchSuppliers}
                            disabled={
                              disabledSearchField(
                                  getFieldValue("startDate"),
                                  getFieldValue("endDate"),
                                  getFieldValue("pay"),
                              )
                            }
                            value={""}
                        />
                    )}
                  </Form.Item>
                  <Button type="primary" onClick={onAdd}>
                    Adicionar
                  </Button>
                </div>
              </Col>
              <RowFormat>
                <ColFormat>
                  <TextField
                      name="typeSupplier"
                      label="Tipo de Prestador"
                      disabled
                  />
                </ColFormat>
                <ColFormat>
                  <TextField
                      name="seniority"
                      label="Senioridade"
                      disabled
                  />
                </ColFormat>
                <ColFormat>
                  {
                      suppliers[0]?.typeSupplier === 'FREELANCE' &&
                      <TextField
                          name="unitMaximunPrice"
                          label="Preço Máximo [Freela]"
                          disabled
                      />
                  }
                </ColFormat>
              </RowFormat>
              <Col>
                <Table
                  columns={
                    provider === 1 ? columnsFreelancer : columnsSuppliers
                  }
                  dataSource={suppliers}
                  pagination={false}
                />
              </Col>
              {
                  suppliers.length > 0 &&
                  <Col>
                    <MessageContainer>
                      <Paragraph>
                        Para modificar as datas, exclua o prestador escolhido.
                      </Paragraph>
                    </MessageContainer>
                  </Col>
              }
              <Col>
                <div style={{ marginBottom: "40px" }}></div>
              </Col>
              <Row>
                <Col xl={24}>
                  <div style={{ marginTop: "20px" }}>
                    <b>Linhas e produtos escolhidos para a requisição</b>
                  </div>
                </Col>
                <Col xl={24}>
                  <Table
                    columns={columnsLines}
                    dataSource={productsLinesNotEdited}
                    pagination={false}
                  />
                </Col>
                <Col xl={24}>
                  <div style={{ marginTop: "20px" }}>
                    <b>Edite abaixo a quantidade, diarias e o valor dessa requisição</b>
                  </div>
                </Col>
                <Col xl={24}>
                  <Table
                      columns={columnsLinesEditable}
                      dataSource={productsLines}
                      pagination={false}

                  />
                </Col>
                <Col>
                  <div style={{ marginBottom: "40px" }}></div>
                </Col>
              </Row>
              <Row>
                <Col xl={24}>
                  <div style={{ marginTop: "20px" }}>
                    <b>Escopo técnico detalhado e anexos</b>
                  </div>
                </Col>
                <Col xl={24}>
                  <AreaField
                    required
                      name="technicalScope"
                      maxLength={-1}
                      label="Escopo técnico detalhado"
                      rules={[
                        {
                          required: true,
                          message: "Escopo técnico obrigatório",
                        },
                      ]}
                  />
                </Col>
                <Col xl={24}>
                  <UploadWrapper>
                    <Dragger {...props} showUploadList={false}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CloudUploadOutlined
                          style={{ fontSize: "24px", marginTop: "-5px" }}
                        />
                        <p className="ant-upload-text">
                          Clique ou arraste para realizar o upload
                        </p>
                      </div>
                    </Dragger>
                  </UploadWrapper>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div></div>
                </Col>
                <Col xl={24}>
                  <Table
                    pagination={false}
                    dataSource={filesItems}
                    columns={[
                      {
                        title: "Nome do arquivo",
                        dataIndex: "name",
                        key: "name",
                      },
                      {
                        title: "Formato",
                        dataIndex: "type",
                        key: "type",
                      },
                      {
                        title: "",
                        dataIndex: "delete",
                        key: "delete",
                        render(_: any, record: any) {
                          return (
                            <span
                              onClick={() => removeFile(record)}
                              style={{ cursor: "pointer" }}
                            >
                              excluir
                            </span>
                          );
                        },
                      },
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Divider />
                </Col>
              </Row>
              <Row>
                <Col xl={24}>
                  <div style={{ marginTop: "20px" }}>
                    <b>Questões complementares para a alçada de aprovação</b>
                  </div>
                </Col>
                <Col xl={24}>
                  <AreaField
                    name="objectiveFunction"
                    maxLength={-1}
                    label="Qual o objetivo da contratação? Temos essa função dentro de casa? Avaliamos a disponibilidade?"
                    onChange={(event) =>
                      (initialValues.objectiveFunction = event.target.value)
                    }
                    rules={[
                      {
                        required: true,
                        message: "justificativa obrigatória",
                      },
                    ]}
                    required
                  />
                </Col>
              </Row>
              <Button style={{ margin: "0 4px" }} onClick={prev}>
                Voltar
              </Button>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.providerType !== currentValues.providerType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("providerType") === 1 ||
                  getFieldValue("providerType") === "" ? (
                    <Button
                      style={{ margin: "0 4px" }}
                      type="primary"
                      htmlType="submit"
                      onClick={() => setBtnClick("next")}
                    >
                      Próximo
                    </Button>
                  ) : (
                    <Button
                      style={{ margin: "0 4px" }}
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      onClick={() => setBtnClick("save")}
                    >
                      Salvar
                    </Button>
                  )
                }
              </Form.Item>
            </>
          </Form>
        </div>
      </Section>
      <Modal
        title="Requisições"
        className="horizontal-scrollable-modal"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        width={1300}
      >
        <Table
          columns={requestsColumns}
          dataSource={requestModal}
          pagination={false}
          style={{ overflowX: "auto" }}
        />
      </Modal>
    </>
  );
};

export default FormRequest;
