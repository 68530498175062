import React, { useEffect, useState } from "react";
import {
  Form,
  Radio,
  Divider,
  Table,
  Tag,
  Rate,
  Popover,
  Checkbox,
  Button,
  Modal,
  Spin
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import {
  PageHeader,
  Section,
  Row,
  Col,
  TextField,
  SelectField,
  DateField,
  CurrencyField,
  CheckField,
  SearchField,
  AreaField,
} from "components";

import {
  SupplierStatus,
  supplierStatus,
  SupplierType,
} from "../../../../utils/constants";

import { useParams } from "react-router-dom";

import moment from "moment";

import { useRequest, useProduct, useSuppliers } from "../../../../repositories";

import { useNavigate } from "react-router";

import axios from "services/axios";
import { formatCurrency, formatDateToView } from "../../../../utils/helpers";

import { ContainerTable } from './styles'
import {BoxInfoSuspend, ColSuspend, RowSuspend} from "./styles";

const options = [
  { label: "Monday", value: "Monday" },
  { label: "Compras", value: "Compras" },
  { label: "Elaw", value: "Elaw" },
  { label: "Cervello", value: "Cervello" },
  { label: "Power BI", value: "Power BI" },
  { label: "TOTVS", value: "TOTVS" },
  { label: "Pacote Office", value: "Pacote Office" },
];


const requestsColumns: any = [
  {
    title: "Emissão",
    dataIndex: "issueDate",
    render: (_: any, record: any) => {
      return record.issueDate
        ? moment(record.issueDate).format("DD/MM/YYYY")
        : "";
    },
  },
  {
    title: "Vencimento",
    dataIndex: "maturity",
    render: (_: any, record: any) => {
      return record.maturity
        ? moment(record.maturity).format("DD/MM/YYYY")
        : "";
    },
  },
  {
    title: "Produto",
    dataIndex: "productName",
  },
  {
    title: "Prestador",
    dataIndex: "typeProvider",
    render: (_: any, record: any) => {
      return record.typeProvider === "SUPPLIER" ? "Fornecedor" : "Freelancer";
    },
  },
  {
    title: "Tipo",
    dataIndex: "typeRequest",
    render: (_: any, record: any) => {
      if (record.typeRequest === "SIMPLE") return "Simples";
      return "Composta";
    },
  },
  {
    title: "Categoria",
    dataIndex: "category",
  },
  {
    title: "Alocação",
    dataIndex: "allocation",
  },
  {
    title: "Local",
    dataIndex: "buName",
  },
  {
    title: "Cliente",
    dataIndex: "client",
  },
  {
    title: "Timing",
    dataIndex: "timing",
    render: (_: any, record: any) => {
      if (record.timing === "PLANEJADA")
        return <Tag color="green">Planejada</Tag>;

      return <Tag color="red">Não planejada</Tag>;
    },
  },
  {
    title: "Fatura",
    dataIndex: "paymentType",
  },
  {
    title: "Valor da requisição",
    dataIndex: "totalCost",
    render: (_: any, record: any) => {
      return formatCurrency(record?.totalCost);
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (_: any, record: any) => {
      switch (record.status) {
        case "NEW":
          return <Tag color="cyan">Novo</Tag>;
        case "IN_QUOTATION":
          return <Tag color="yellow">Em cotação</Tag>;
        case "APPROVED":
          return <Tag color="green">Aprovado</Tag>;
        case "REFUSED":
          return <Tag color="orange">Reprovada</Tag>;
        case "CANCELED":
          return <Tag color="red">Cancelada</Tag>;
        case "ON_APPROVAL":
          return <Tag color="yellow">Em aprovação</Tag>;
        case "PAID_OUT":
          return <Tag color="green">Pago</Tag>;
        default:
          return record.status;
      }
    },
  },
];

function applyPhoneMaskWithNinthDigit(phoneNumber: any) {
  const onlyNumbers = phoneNumber.replace(/\D/g, "");

  let formattedNumber;
  if (onlyNumbers.length === 11) {
    formattedNumber = onlyNumbers.replace(
      /(\d{2})(\d{5})(\d{4})/,
      "($1) $2-$3"
    );
  } else if (onlyNumbers.length === 10) {
    formattedNumber = onlyNumbers.replace(
      /(\d{2})(\d{4,5})(\d{4})/,
      "($1) $2-$3"
    );
  } else {
    return phoneNumber;
  }

  return formattedNumber;
}

const columnsSuppliers: any = [
  {
    title: "Id",
    dataIndex: "id",
  },
  {
    title: "Razão Social",
    dataIndex: "corporateName",
  },
  {
    title: "Nome fantasia ",
    dataIndex: "fantasyName",
  },
  {
    title: "Tipo de Prestador",
    dataIndex: "typeSupplier",
    render: (_: any, record: any) =>
      record.typeSupplier === SupplierType.SUPPLIER
        ? "Fornecedor"
        : "Freelancer",
  },
  {
    title: "Telefone",
    dataIndex: "telephone",
    render: (_: any, record: any) => {
      return applyPhoneMaskWithNinthDigit(record.telephone);
    },
  },
  {
    title: "E-mail",
    dataIndex: "email",
  },
  {
    title: "Status Homologação",
    dataIndex: "status",
    render: (_: any, record: any) => {
      if (record.status === SupplierStatus.APPROVED) {
        return <Tag color="green">Homologado</Tag>;
      }

      if (record.status === SupplierStatus.NOT_APPROVED) {
        return <Tag color="red">Bloqueado</Tag>;
      }

      if (record.status === SupplierStatus.PRE_REGISTRATION) {
        return <Tag color="blue">{supplierStatus[record.status]}</Tag>;
      }

      return <Tag>{supplierStatus[record.status]}</Tag>;
    },
  },
  {
    title: "Preciario",
    dataIndex: "preciary",
    align: "center",
    render: (_: any, record: any) => {
      if (record.preciary) {
        return <Tag>Sim</Tag>;
      } else {
        return <Tag>Não</Tag>;
      }
    },
  },
  {
    title: "Valor negociado",
    dataIndex: "value",
    align: "center",
    render: (_: any, record: any) => (
      <span>{formatCurrency(record.value)}</span>
    ),
  },
  {
    title: "Unidade de medida",
    dataIndex: "unitOfMeasurement",
    align: "center",
  },
  // {
  //   title: "Avaliações",
  //   dataIndex: "currentAmountEvaluation",
  //   align: "center",
  //   render: (_: any, record: any) => (
  //     <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
  //       <Rate disabled value={record.currentEvaluation} />
  //       <span>{`(${record.currentAmountEvaluation})`}</span>
  //     </div>
  //   ),
  // },
];

const RequestDetail = () => {
  const [form] = Form.useForm();
  const [freelancersSuppliersList, setFreelancersSuppliersList] = useState<any>(
    []
  );
  const [statusRequest, setStatusRequest] = useState("");
  const [formCanceled, setFormCanceled] = useState(false);


  const [technicalScope, setTechnicalScope] = useState<any>("");

  const [productsList, setProductsList] = useState<any>([]);
  const [productsLinesNotEdited, setProductsLinesNotEdited] = useState<any>([]);
  const [filesList, setFilesList] = useState<any>([]);
  const [requestModal, setRequestModal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [items, setItems] = useState([]);

  const [products, setProducts] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  const navigate = useNavigate();

  const [checkedValues, setCheckedValues] = useState([]);

  const repository = useRequest();
  const repositoryProduct = useProduct();
  const repositorySuplier = useSuppliers();

  const [downloadLoading, setDownloadLoading] = useState(false);

  const [columnsInstallment, setColumnsInstallment] = useState<any>([]);

  const [isViewTable, setIsViewTable] = useState<any>(false);

  const [linesData, setLinesData] = useState<any>([]);

  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const voltarParaPaginaAnterior = () => {
    navigate(-1);
  };

  const openRequests = (requests: any) => {
    setRequestModal(requests.filter((request: any) => request.status !== "CANCELED"));
    setIsModalOpen(true);
  };

  const columnsLines: any = [
    {
      title: "Id Compras",
      dataIndex: "id",
    },
    {
      title: "Id da linha TOTVS",
      dataIndex: "idLinha",
    },
    {
      title: "Tipo do Faturamento",
      dataIndex: "paymentType",
    },
    {
      title: "Produto",
      dataIndex: "productDescription",
    },
    {
      title: "Categoria",
      dataIndex: "categoria",
    },
    {
      title: "Escopo da linha",
      dataIndex: "technicalScope",
      key: "technicalScope",
      render: (_: any, record: any) => {
        return (
          <Popover content={record.technicalScopeDescription} overlayStyle={{ maxWidth: "800px" }}>
            {record.technicalScope}
          </Popover>
        );
      },
    },
    {
      title: "Quantidade",
      dataIndex: "amount",
      render: (_: any, record: any) => {
        return record.amount;
      },
    },
    {
      title: "Diárias",
      dataIndex: "daily",
      render: (_: any, record: any) => {
        return record.daily;
      },
    },
    {
      title: "Custo Unítario",
      dataIndex: "unitCost",
      render: (_: any, record: any) => {
        return formatCurrency(record.unitCost);
      },
    },
    {
      title: "Custo total",
      dataIndex: "totalCost",
      render: (_: any, record: any) => {
        return formatCurrency(record.totalCost);
      },
    },
    {
      title: "Já tem requisições?",
      dataIndex: "withoutRequest",
      align: "center",
      render: (_: any, record: any) => {
        return record.withoutRequest ? (
          <Tag
            color="green"
            style={{ cursor: "pointer" }}
            onClick={() => openRequests(record.projectRequestItems)}
          >
            Sim
          </Tag>
        ) : (
          <Tag color="default">Não</Tag>
        );
      },
    },
    {
      title: "Requisições",
      dataIndex: "approvalRequests",
      render: (_: any, record: any) => {
        return formatCurrency(record.approvalRequests);
      },
    },
    {
      title: "Saldo da linha",
      dataIndex: "lineBalance",
      render: (_: any, record: any) => {
        return (
          <span style={{ whiteSpace: "nowrap" }}>
            {formatCurrency(record.lineBalance)}
          </span>
        );
      },
    },
  ];
  const columnsLinesEditable: any = [
    {
      title: "Id da linha TOTVS",
      dataIndex: "idLinha",
    },
    {
      title: "Tipo do Fat.",
      dataIndex: "paymentType",
    },
    {
      title: "Produto",
      dataIndex: "productDescription",
    },
    {
      title: "Categoria",
      dataIndex: "categoria",
    },
    {
      title: "Escopo",
      dataIndex: "technicalScope",
      key: "technicalScope",
      render: (_: any, record: any) => {
        return (
            <Popover
                content={record.technicalScopeDescription}
                overlayStyle={{ maxWidth: "800px" }}
            >
              {record.technicalScope}
            </Popover>
        );
      },
    },
    {
      title: "Quant",
      dataIndex: "amount",
      render: (_: any, record: any) => {

        return record.amount
      },
    },
    {
      title: "Diárias",
      dataIndex: "daily",
      render: (_: any, record: any) => {
        return record.daily
      },
    },
    {
      title: "Custo Unítario",
      dataIndex: "unitCost",
      render: (_: any, record: any) => {
        return formatCurrency(record.unitCost)
      },
    },
    {
      title: "Custo total",
      dataIndex: "totalCost",
    },
  ];

  const columnsApportionmentWithInstallment = [
    {
      title: "Id Projeto",
      dataIndex: "projectIdERP",
    },
    {
      title: "Projeto",
      dataIndex: "projectName",
      onHeaderCell: () => ({
        style: { minWidth: 220 },
      }),
    },
    {
      title: "Linha",
      dataIndex: "lineIdErp",
      onHeaderCell: () => ({
        style: { minWidth: 220 },
      }),
      render: (_: any, record: any) => (
        <Popover content={record?.lineIdErp} title="Linha - Descrição">
          {`${record?.lineIdErp?.substring(0, 22)}...`}
        </Popover>
      ),
    },
    {
      title: "Percentual Rateio (%)",
      dataIndex: "percentage",
      render: (_: any, record: any) => {
        return `${record?.percentage?.toFixed(2)} %`;
      },
    },
  ]

  let initialValues = {
    typeRequest: "",
    category: "Compra & Contratação",
    allocation: "Com projeto",
    requestLocation: "",
    client: "",
    invoiceAgainst: "TV1",
    status: "",
    purchaseType: "Projeto",
    providerType: "",
    senioridade: "",
    timing: 2,
    emission: moment(),
    startDate: moment(),
    endDate: moment(),
    pay: moment(),
    ExpenseOrInvestment: "Despesa",
    productType: "",
    quoteDeadline: "",
    SupplierPricing: "",
    unitMinimunPrice: 0,
    unitMaximunPrice: 0,
    productWithPrice: "",
    cotation: "",
    technicalScope: "",
    objectiveFunction: "",
    justifyIfItIsAnEmergency: "",
    JustifyReasonSingleSupplier: "",
    JustifyUnapprovedSupplier: "",
    JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate: "",
    JustifyTheDueDateBeingShorter: "",
    indicationSingleSupplierJustifiable: "",
    needSupportFromTv1corporateTI: "",
    useTv1Email: "",
    equipmentFreelancers: "",
    equipmentTypeFreelancers: "",
    workplace: "",
    accessInternalSystem: "",
    othersAccessInternalSystem: "",
    otherMarketSoftware: "",
    freelanceDirectManager: "",
    contractStartDate: "",
    contractEndDate: "",
  };

  const loadData = async () => {

    setLoading(true)

    let requestResponse = await repository.findRequestById(id, {});

    setStatusRequest(requestResponse.statusRequest)
    setFormCanceled(requestResponse.statusRequest === "SUSPEND" || requestResponse.statusRequest === "CANCELED" ? true : false)

    const sortedItems = {
      ...requestResponse,
      items: requestResponse?.purchaseOrder?.items?.map((item: any) => ({
        ...item,
        apportions: item?.apportions?.sort((a: any, b: any) => {
          const dateA: any = new Date(a.maturityApportion);
          const dateB: any = new Date(b.maturityApportion);
          return dateA - dateB;
        })
      }))
    }

    const columnsInstallment: any = []

    sortedItems?.items[0]?.apportions?.forEach((apportion: any, index: any) => {

      let totalValue = 0;

      sortedItems?.items?.forEach((item: any) => {
          item.apportions.forEach((apport: any) => {
              if (apport.dueDate === apportion.dueDate) {
                  totalValue += apport.value;
              }
          });
      });

      columnsInstallment.push({
        title: `${index + 1}° Parcela \n ${formatDateToView(apportion.dueDate)} ${formatCurrency(totalValue)}`,
        dataIndex: `${index + 1}installment`,
        render: (apportion: any, record: any) => {
          return <span>{formatCurrency(record?.apportions[index]?.value)}<br />{record?.apportions[index]?.idErpCorrelation}</span>
        }
      });
    });

    const updatedColumns = [
      ...columnsApportionmentWithInstallment,
      ...columnsInstallment,
      {
        title: `Total ${formatCurrency(sortedItems?.items?.reduce((sum: any, item: any) => sum + item.apportions?.reduce((soma: any, valorAtual: any) => soma + valorAtual?.value, 0), 0))}`,
        dataIndex: "totalValue",
        render: (_: any, record: any) => {
          return <span>{formatCurrency(record?.apportions?.reduce((soma: any, valorAtual: any) => soma + valorAtual?.value, 0))}</span>
        }
      },
    ]

    setColumnsInstallment(updatedColumns)

    let mappedRecord: any = []

    sortedItems?.items?.forEach((item: any) => {

      mappedRecord = [ ...mappedRecord, {
        projectIdERP: item?.projectIdErp,
        projectName: item?.projectName,
        lineIdErp: `${item?.projectRevisionItem?.lineIdErp} - ${item?.projectRevisionItem?.description}`,
        percentage: item?.percentage,
        apportions: item.apportions
      }]

    });

    setItems(mappedRecord)

    const linesAndProducts = requestResponse?.purchaseOrder?.items.map((item: any) => ({
      ...item,
      id: String(item?.projectRevisionItem?.id),
      key: item?.projectRevisionItem?.id,
      idLinha: item?.projectRevisionItem?.lineIdErp,
      paymentType: item?.projectRevisionItem?.billingType === "0" ? "TV1" : "Cliente",
      productId: item?.projectRevisionItem?.productId,
      productDescription: item?.projectRevisionItem?.productDescription,
      categoria: item?.projectRevisionItem?.categories[0].description,
      technicalScope: item?.projectRevisionItem?.description ? item?.projectRevisionItem?.description?.slice(0, 10) + " ..." : '',
      amount: item?.projectRevisionItem?.amount,
      daily: item?.projectRevisionItem?.daily,
      unitCost: item?.projectRevisionItem?.unitCost,
      withoutRequest: item?.projectRevisionItem?.existsRequisitions,
      packageOrApportionment: item?.projectRevisionItem?.activePackageOrRating,
      approvalRequests: item?.projectRevisionItem?.valueApprovedRequisitions,
      totalCost: item?.projectRevisionItem?.totalCost,
      lineBalance: item?.projectRevisionItem?.valueApprovedRequisitions ? item?.projectRevisionItem?.balanceLine : item?.projectRevisionItem?.totalCost,
      projectRequestItems: item?.projectRevisionItem?.projectRequestItems,
    }))

    setProductsLinesNotEdited(linesAndProducts)

    let productsArr: any = []
    linesAndProducts?.forEach((product: any) => {
      if(!productsArr.includes(product?.productDescription)) {
        productsArr = [...productsArr, product?.productDescription]
      }
    });
    setProducts(productsArr)
    setProductOptions(productsArr.map((pro: any, index: any) => ({id: index, value: pro})))
    form.setFieldValue('productSelection', productsArr)

    const productResponse = await repositoryProduct.findProductById(
      requestResponse.items[0].productId
    );

    const supplierResponse = await repositorySuplier.findSupplierByFilter({
      id: requestResponse.suppliers[0].id,
    });

    const productPriceRangeResponse =
      await repositorySuplier.getProductPriceRange(
        requestResponse.suppliers[0].id
      );

    const paramsProjectItems: any = {
      projectId: requestResponse.items[0].projectRevision.project.id,
      projectRevisionId: requestResponse.items[0].projectRevision.id,
      size: 10000
    };

    const responseProjectItems = await repository.findProjectItems(
      paramsProjectItems
    );
    if (!responseProjectItems) return;

    const valuesProducts: any[] = [];

    let valuesNotEdited: any = []

    for (let i = 0; i < requestResponse.items.length; i++) {
      const item = responseProjectItems.content[0]?.items
          .map((request: any) => ({
            id: String(request.id),
            key: request.id,
            idLinha: request.lineIdErp,
            paymentType: request.billingType === "0" ? "TV1" : "Cliente",
            productId: request.productId,
            productDescription: request.productDescription,
            categoria: request.categories[0].description,
            technicalScope: request?.description ? request?.description?.slice(0, 10) + " ..." : '',
            technicalScopeDescription: request?.description,
            amount: request.amount,
            daily: request.daily,
            unitCost: request.unitCost,
            totalCost: request.totalCost,
            withoutRequest: request.existsRequisitions,
            packageOrApportionment: request.activePackageOrRating,
            approvalRequests: request.valueApprovedRequisitions,
            lineBalance: request.valueApprovedRequisitions ? request.balanceLine : request.totalCost,
            projectRequestItems: request.projectRequestItems,
          }))
          .filter(
              (item: any) =>
                  item.productId === requestResponse.items[i].productId &&
                  String(item.idLinha) === String(requestResponse.items[i].lineIdErp)
          );

      const valuesProduct = {
        ...item[0],
        categoria: productResponse?.categories[0]?.description,
        totalCost: formatCurrency(requestResponse?.items[i].totalCost),
        unitCost: (requestResponse?.items[i].totalCost / (requestResponse?.items[i].amount * requestResponse?.items[i].daily)),
        amount: requestResponse?.items[i].amount,
        daily: requestResponse?.items[i].daily
      };
      valuesNotEdited = [...valuesNotEdited, {...item[0]}]
      
      valuesProducts.push(valuesProduct)
    }

    setLinesData(requestResponse?.items.map((item: any) => ({

    })))

    let unitMinimunPriceClone = "";
    let unitMaximunPriceClone = "";

    switch (supplierResponse?.content[0]?.seniority) {
      case "JUNIOR":
        unitMinimunPriceClone = productResponse?.minimumPriceJunior;
        unitMaximunPriceClone = productResponse?.maximumPriceJunior;
        break;
      case "PLENO":
        unitMinimunPriceClone = productResponse?.minimumPricePleno;
        unitMaximunPriceClone = productResponse?.maximumPricePleno;
        break;
      case "SENIOR":
        unitMinimunPriceClone = productResponse?.minimumPriceSenior;
        unitMaximunPriceClone = productResponse?.maximumPriceSenior;
        break;
    }

    let mappedStatus = "";

    switch (requestResponse?.statusRequest) {
      case "NEW":
        mappedStatus = "Novo";
        break;
      case "IN_QUOTATION":
        mappedStatus = "Em cotação";
        break;
      case "APPROVED":
        mappedStatus = "Aprovado";
        break;
      case "REFUSED":
        mappedStatus = "Reprovada";
        break;
      case "CANCELED":
        mappedStatus = "Cancelada";
        break;
      case "SUSPEND":
        mappedStatus = "Suspensa";
        break;
      case "ON_APPROVAL":
        mappedStatus = "Em aprovação";
        break;
      case "PAID_OUT":
        mappedStatus = "Pago";
        break;
      default:
        return requestResponse?.statusRequest;
    }

    const valuesRequest = {
      ...requestResponse,
      product: requestResponse?.items[0]?.productDescription,
      bu: requestResponse?.items[0]?.projectRevision?.project?.buName,
      needSupportFromTv1corporateTI:
        requestResponse.projectRequestFreelancers
          ?.needSupportFromTv1corporateTI === true
          ? "Sim"
          : "Não",
      useTv1Email:
        requestResponse.projectRequestFreelancers?.useTv1Email === true
          ? "Sim"
          : "Não",
      equipmentFreelancers:
        requestResponse.projectRequestFreelancers?.equipmentFreelancers,
      equipmentTypeFreelancers:
        requestResponse.projectRequestFreelancers?.equipmentTypeFreelancers,
      workplace: requestResponse.projectRequestFreelancers?.workplace,
      accessInternalSystem:
        requestResponse.projectRequestFreelancers?.accessInternalSystem,
      othersAccessInternalSystem:
        requestResponse.projectRequestFreelancers?.othersAccessInternalSystem,
      otherMarketSoftware:
        requestResponse.projectRequestFreelancers?.otherMarketSoftware,
      freelanceDirectManager:
        requestResponse.projectRequestFreelancers?.freelanceDirectManager,
      contractStartDate: moment(
        requestResponse.projectRequestFreelancers?.contractStartDate
      ),
      contractEndDate: moment(
        requestResponse.projectRequestFreelancers?.contractEndDate
      ),
      typeRequest:
        requestResponse?.typeRequest === "SIMPLE" ? "Simples" : "Composta",
      client: requestResponse?.items[0]?.projectRevision?.project?.clientName,
      requestLocation:
        requestResponse?.items[0]?.projectRevision?.project?.buName,
      status: mappedStatus,
      timing: requestResponse?.approvalHierarchyTiming === "PLANEJADA" ? "PLANEJADA" : "NÂO PLANEJADA",
      emission: moment(requestResponse?.issueDate).format("DD/MM/YYYY"),
      startDate: moment(requestResponse?.serviceStartDate).format("DD/MM/YYYY"),
      dateTimeSuspended: moment(requestResponse?.dateTimeSuspended).format("DD/MM/YYYY"),
      endDate: moment(requestResponse?.serviceEndDate).format("DD/MM/YYYY"),
      maturity: `${moment(requestResponse?.serviceEndDate).diff(moment(requestResponse?.serviceStartDate), 'days')} Dias`,
      
      providerType:
        requestResponse?.typeProvider === SupplierType.SUPPLIER
          ? "Fornecedor"
          : "Freelancer",
      technicalScope: requestResponse?.items[0]?.technicalScope,
      senioridade: supplierResponse?.content[0]?.seniority,
      unitMinimunPrice: unitMinimunPriceClone,
      unitMaximunPrice: unitMaximunPriceClone,
      ...requestResponse?.projectRequestAdditionalQuestions,
    };

    valuesRequest.productType = productResponse.productType.description;

    const filtered = productPriceRangeResponse.filter(
      (product: any) => product.productId === valuesProducts[0]?.productId
    );
    setTechnicalScope(requestResponse?.items[0]?.technicalScope)
    const valuesSuppliers = {
      ...supplierResponse?.content[0],
      preciary: filtered[0]?.preciary,
      value: filtered[0]?.value ? filtered[0]?.value : "",
      unitOfMeasurement: filtered[0]?.unitOfMeasurement
        ? filtered[0]?.unitOfMeasurement
        : "",
    };

    setFreelancersSuppliersList([valuesSuppliers]);
    setProductsList(valuesProducts);
    setFilesList(requestResponse.files);
    setCheckedValues(
      requestResponse.projectRequestFreelancers?.accessInternalSystem
    );

    form.setFieldsValue(valuesRequest);
    setLoading(false)
  };

  const downloadArchive = async (fileName: any) => {
    try {
      setDownloadLoading(true);
      const response = await axios.get(
        "/v1/manager/project-request/download/" + fileName,
        {
          responseType: "arraybuffer",
        }
      );
      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    } finally {
      setDownloadLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <PageHeader
        title="Detalhes da Requisição"
        breadcrumbItems={["Requisições", "Detalhes"]}
      />
      {
        loading ?
        <Section style={{display: 'flex', justifyContent: 'center', padding: '50px 0'}}>
          <Spin />
        </Section>
        :
        <Section>
          <Form form={form} layout="vertical" initialValues={initialValues}>
            <BoxInfoSuspend visible={formCanceled}>
              <RowSuspend>
                <ColSuspend size="half">
                  <TextField
                      name="dateTimeSuspended"
                      label={statusRequest === "SUSPEND" ? "Data da suspensão:" : "Data do cancelamento:"}
                      disabled
                  />
                </ColSuspend>
                <ColSuspend size="half">
                  <TextField
                      name="reasonSuspended"
                      label={statusRequest === "SUSPEND" ? "Motivo da suspensão" : "Motivo do cancelamento"}
                      disabled
                  />
                </ColSuspend>
              </RowSuspend>
              <RowSuspend>
                <ColSuspend size="full">
                  <AreaField
                      name="descriptionSuspended"
                      label="Justificativa (descreva)"
                      disabled
                  />
                </ColSuspend>
              </RowSuspend>
            </BoxInfoSuspend>
            <Row>
              <Col xl={24}> <b>Categorização, datas e gatilhos</b> </Col>
              <Col xl={6} xxl={6}>
                <TextField
                  name="startDate"
                  label="Data do início do serviço"
                  disabled
                />
              </Col>
              <Col xl={6} xxl={6}>
                <TextField
                  name="endDate"
                  label="Data do Termino do serviço"
                  disabled
                />
              </Col>
              <Col xl={6} xxl={6}>
                <TextField
                  name="timing"
                  label="Timing da requisição"
                  disabled
                />
              </Col>
              <Col xl={6} xxl={6}>
                <TextField name="maturity" label="Tempo total do serviço" disabled />
              </Col>
              <Col xl={6} xxl={6}>
                <SearchField
                  name="productSelection"
                  label="Escolha o produto"
                  options={productOptions}
                  value={products}
                  disabled
                  mode="multiple"
                />
              </Col>
              <Col xl={6} xxl={6}>
                <TextField name="bu" label="BU" disabled />
              </Col>
            </Row>
            <Row>
            <Col xl={6} xxl={3}>
                <TextField
                  name="providerType"
                  label="Tipo de Prestador"
                  disabled
                />
              </Col>
              <Col xl={6} xxl={3}>
                <TextField
                  name="senioridade"
                  label="Senioridade"
                  disabled
                />
              </Col>
              <Col xl={6} xxl={3}>
                <CurrencyField
                  name="unitMaximunPrice"
                  label="Preço Máximo [Freela]"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Divider />
              </Col>
              <Col xl={24}> <b>Fornecedores ou freelancers para essa requisição</b> </Col>
              <Col>
                <ContainerTable>
                <Table
                  columns={columnsSuppliers}
                  dataSource={freelancersSuppliersList}
                  pagination={false}
                />
                </ContainerTable>
              </Col>
              <Col>
                <div style={{ marginBottom: "40px" }}></div>
              </Col>
            </Row>
            <Row>
              <Col xl={24}>
                <div style={{ marginTop: "20px" }}>
                  <b> Linhas e produtos escolhidos para a requisição </b>
                </div>
              </Col>
              <Col xl={24}>
                <ContainerTable>
                <Table
                  columns={columnsLines}
                  dataSource={productsLinesNotEdited}
                  pagination={false}
                  size='middle'
                />
                </ContainerTable>
              </Col>
              <Col>
                <div style={{ marginBottom: "40px" }}></div>
              </Col>
            </Row>
            <Row>
              <Col xl={24}>
                <div style={{ marginTop: "20px" }}>
                  <b>Programação do rateio</b>
                </div>
              </Col>
              <Col xl={24}>
                <ContainerTable>
                <Table
                  columns={columnsInstallment}
                  dataSource={items}
                  pagination={false}
                />
                </ContainerTable>
              </Col>
              <Col>
                <div style={{ marginBottom: "40px" }}></div>
              </Col>
            </Row>
            <Row>
              <Col xl={24}>
                <div style={{ marginTop: "20px" }}>
                  <b>Questões complementares para a alçada de aprovação</b>
                </div>
              </Col>
              <Col xl={24}>
                <AreaField
                  name="hiringPurpose"
                  label="Qual o objetivo da contratação? Temos essa função dentro de casa? Avaliamos a disponibilidade?"
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col xl={24}>
                <div style={{ marginTop: "20px" }}>
                  Escopo técnico detalhado e anexos
                </div>
              </Col>
              <Col xl={24}>
                <AreaField name="technicalScope" disabled />
              </Col>
            </Row>
            <Row>
              <Col>
                <label> <b>Arquivos</b> </label>
                <Divider />
              </Col>
              <Col xl={24}>
                <Table
                  pagination={false}
                  dataSource={filesList}
                  columns={[
                    {
                      title: "Nome do arquivo",
                      dataIndex: "fileName",
                      key: "fileName",
                    },
                    {
                      title: "Formato",
                      dataIndex: "type",
                      key: "type",
                      render(_: any, record: any) {
                        return record?.fileName?.split(".").pop();
                      },
                    },
                    {
                      title: "",
                      dataIndex: "Dawnload",
                      key: "dawnload",
                      render(_: any, record: any) {
                        return (
                          <Button
                            type="link"
                            icon={<DownloadOutlined />}
                            size="small"
                            onClick={() => downloadArchive(record.fileName)}
                            loading={downloadLoading}
                          >
                            Baixar
                          </Button>
                        );
                      },
                    },
                  ]}
                />
              </Col>
            </Row>

            {freelancersSuppliersList.length > 0 &&
            freelancersSuppliersList[0].typeSupplier === "FREELANCE" ? (
              <Form.Item className="mt-1">
                <Divider />
                <Row>
                  <Col>
                    <label>
                      Formulário funcional complementar para Freelancers
                    </label>
                    <Divider />
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} xxl={4}>
                    <SelectField
                      name="needSupportFromTv1corporateTI"
                      label="Requer suporte da TI da TV1?"
                      disabled
                      options={[
                        {
                          label: "Sim",
                          value: "sim",
                        },
                        {
                          label: "Não",
                          value: "nao",
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={6} xxl={4}>
                    <SelectField
                      name="useTv1Email"
                      label="Usará e-mail da TV1?"
                      disabled
                      options={[
                        {
                          label: "Sim",
                          value: "sim",
                        },
                        {
                          label: "Não",
                          value: "nao",
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={6} xxl={4}>
                    <SelectField
                      name="equipmentFreelancers"
                      label="Equipamento"
                      disabled
                      options={[
                        {
                          label: "Próprio",
                          value: "OWN",
                        },
                        {
                          label: "TV1",
                          value: "TV1",
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={6} xxl={4}>
                    <SelectField
                      name="equipmentTypeFreelancers"
                      label="Tipo de Equipamento"
                      disabled
                      options={[
                        {
                          label: "Notebook",
                          value: "NOTEBOOK",
                        },
                        {
                          label: "Desktop",
                          value: "DESKTOP",
                        },
                        {
                          label: "IMac",
                          value: "IMAC",
                        },
                        {
                          label: "MacBook",
                          value: "MACBOOK",
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={6} xxl={4}>
                    <SelectField
                      name="workplace"
                      label="Local de trabalho"
                      disabled
                      options={[
                        {
                          label: "Escritório TV1",
                          value: "tv1_office",
                        },
                        {
                          label: "Externo",
                          value: "external",
                        },
                        {
                          label: "Home Office",
                          value: "home_office",
                        },
                      ]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Acesso a sistemas internos da TV1:</label>
                  </Col>
                  <Col>
                    <Checkbox.Group
                      name="accessInternalSystem"
                      options={options}
                      value={checkedValues}
                      disabled
                    />
                  </Col>
                  <br />
                  <br />
                </Row>
                <Row>
                  <Col xl={12} xxl={12}>
                    <AreaField
                      name="othersAccessInternalSystem"
                      label="Outros (descreva)"
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={12} xxl={12}>
                    <TextField
                      name="otherMarketSoftware"
                      label="Outros softwares de mercado:"
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={12} xxl={12}>
                    <TextField
                      name="freelanceDirectManager"
                      label="Quem é o Gestor direto do Freelancer no período em que ele prestar serviço para a TV1?"
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} xxl={4}>
                    <DateField
                      name="contractStartDate"
                      label="*Data do início do contrato."
                      disabled
                    />
                  </Col>
                  <Col xl={6} xxl={4}>
                    <DateField
                      name="contractEndDate"
                      label="*Data do fim do contrato."
                      disabled
                    />
                  </Col>
                </Row>
              </Form.Item>
            ) : (
              ""
            )}
          </Form>
          <Divider></Divider>
          <Button onClick={voltarParaPaginaAnterior} htmlType="button">
            Voltar
          </Button>
        </Section>
      }
      <Modal
        title="Requisições"
        className="horizontal-scrollable-modal"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        width={1300}
      >
        <Table
          columns={requestsColumns}
          dataSource={requestModal}
          pagination={false}
          style={{ overflowX: "auto" }}
        />
      </Modal>
    </>
  );
};

export default RequestDetail;
