import React, {useEffect, useState} from 'react'
import {
  Form,
  Drawer,
  Button,
  Space, Modal
} from 'antd'
import {
  Col,
  DateField, MaskedField,
  Row,
  SelectField, TextField,
} from 'components'
import {useBusinessUnits, useClient, useProduct, useSuppliers, useUsers} from "../../../../repositories";
import {DefaultOptionType} from "antd/lib/select";

type TFilterListFilterSupplierParamsForm = {
  startIssueDate: string
  endIssueDate: string
  startMaturity: string
  endMaturity: string
  category: string
  allocation: string
  buId: string
  projectIdERP: string
  clientId: string
  timing: string
  statusRequest: string
  requesterId: string
  productId: string
  typeProvider: string
  supplierId: string
  supplierIdCnpj: string
  billingType: string
  costCenterId: string
  idRequest: string
  idTotvs: string
}

const initialValues: TFilterListFilterSupplierParamsForm = {
  startIssueDate: "",
  endIssueDate: "",
  startMaturity: "",
  endMaturity: "",
  category: "",
  allocation: "",
  buId: "",
  projectIdERP: "",
  clientId: "",
  timing: "",
  statusRequest: "",
  requesterId: "",
  productId: "",
  typeProvider: "",
  supplierId: "",
  supplierIdCnpj: "",
  billingType: "",
  costCenterId: "",
  idRequest: "",
  idTotvs: ""
}

const optionsTypeProvider: any[] = [
  {
    label: "Fornecedor",
    value: "SUPPLIER",
  },
  {
    label: "Freelancer",
    value: "FREELANCE",
  },
];
const optionsBillingType: any[] = [
  {
    label: "TV1",
    value: "0",
  },
  {
    label: "Cliente",
    value: "1",
  },
];
const optionsTiming: any[] = [
  {
    label: "Planejada",
    value: "PLANEJADA",
  },
  {
    label: "Não planejada",
    value: "EMERGENCIAL",
  },
];
const optionsCategory: any[] = [
  {
    label: "Compra & Contratação",
    value: "Compra & Contratação",
  },
  {
    label: "Rateios e Contratos",
    value: "Rateios e Contratos",
  },
];
const optionsAllocation: any[] = [
  {
    label: "Com Projeto",
    value: "Com projeto",
  },
];
const optionsStatus: any[] = [
  {
    value: "NEW",
    label: "Novo",
  },
  {
    value: "ON_APPROVAL",
    label: "Em aprovação",
  },
  {
    value: "APPROVED",
    label: "Aprovado",
  },
  {
    value: "CANCELED",
    label: "Cancelada",
  },
  {
    value: "SUSPEND",
    label: "Suspensa",
  },
  {
    value: "REFUSED",
    label: "Reprovada",
  },
  {
    value: "PAID_OUT",
    label: "Pago",
  },
];

type TProps = {
  isVisibleDrawer: boolean
  setIsVisibleDrawer: React.Dispatch<boolean>,
  filters: TFiltersHook
  resetPagination: any
}

const FiltersForm = (props: TProps) => {

  const buRepository = useBusinessUnits();
  const clientRepository = useClient();
  const userRepository = useUsers();
  const productRepository = useProduct()
  const supplierRepository = useSuppliers()

  const [userOptions, setUserOptions] = useState<DefaultOptionType[]>([]);
  const [optionsBU, setBUOptions] = useState<DefaultOptionType[]>([]);
  const [optionsClients, setClientsOptions] = useState<DefaultOptionType[]>([]);
  const [optionsProduct, setProductOptions] = useState<DefaultOptionType[]>([]);
  const [optionsSuppliers, setSuppliersOptions] = useState<DefaultOptionType[]>([]);

  const {
    isVisibleDrawer,
    setIsVisibleDrawer,
    filters,
    resetPagination
  } = props
  const [form] = Form.useForm<TFilterListFilterSupplierParamsForm>()

  const handleClose = () => {
    setIsVisibleDrawer(false)
  }

  const handleSubmit = async () => {
    const validatedData = await form.validateFields()
    if (!validatedData) return

    resetPagination()
    onFinish(validatedData)
    handleClose()
  }

  useEffect(() => {
    requestBUOptions()
    requestClientsOptions()
    requestUserOptions()
    requestProductOptions()
    requestSuppliersOptions()
    if (!filters.items?.length) form.resetFields()
  }, [filters.items, form])

  const onFinish = (values: TFilterListFilterSupplierParamsForm) => {
    const _values = Object.entries(values)

    const filterNonNullValues = _values.filter(value => {
      const [, term] = value
      return Boolean(term)
    })
    const _filters = [] as TFilter[]
    filterNonNullValues.forEach(value => {
      const [field, term] = value
      _filters.push({ field, term: String(term) })
    })

    filters.clearAndAdd(_filters)
  }

  const onClickResetFilters = () => filters.clearAndAdd([])

  const requestBUOptions = async () => {
    const response = await buRepository.findBus({
      page: 0,
      size: 10000,
    });
    if (!response) return;

    const mapped = response.content.map((item: any) => ({ value: item.id, label: item.name }));
    mapped.sort((a: { label: string; }, b: { label: string; }) => a.label.localeCompare(b.label));

    if (!response) return;
    setBUOptions(mapped);
  };

  const requestUserOptions = async () => {

    const response = await userRepository.findByFilterUsers({
      page: 0,
      size: 10000,
    });
    if (!response) return;
    const mapped = response.content.map((item: any) => ({ value: item.id, label: item.name }));

    mapped.sort((a, b) => a.label.localeCompare(b.label));


    if (!response) return;

    setUserOptions(mapped);
  };

  const requestClientsOptions = async () => {

    const response = await clientRepository.findClientByFilter({
      page: 0,
      size: 10000,
    });
    if (!response) return;

    const mapped = response.content.map((item: any) => ({ value: item.id, label: item.corporateName }));
    mapped.sort((a, b) => a.label.localeCompare(b.label));


    if (!response) return;
    setClientsOptions(mapped);
  };
  const requestProductOptions = async () => {
    const response = await productRepository.findProductByFilter({
      page: 0,
      size: 10000,
    });
    if (!response) return;

    const mapped = response.content.map((item: any) => ({ value: item.id, label: item.description }));
    mapped.sort((a, b) => a.label.localeCompare(b.label));

    if (!response) return;
    setProductOptions(mapped);
  };
  const requestSuppliersOptions = async () => {
    const response = await supplierRepository.findSupplierByFilter({
      page: 0,
      size: 10000,
    });
    if (!response) return;

    const mapped = response.content.map((item: any) => ({ value: item.id, label: item.corporateName }));
    mapped.sort((a, b) => a.label.localeCompare(b.label));

    if (!response) return;
    setSuppliersOptions(mapped);
  };

  return (
    <Drawer
        title='Filtros'
        width={500}
        onClose={handleClose}
        visible={isVisibleDrawer}
        destroyOnClose
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <DateField name="startIssueDate" label="Emissão de" />
          </Col>

          <Col md={12} xl={12} xxl={12}>
            <DateField name="endIssueDate" label="Emissão até" />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <DateField name="startMaturity" label="Vencimento de" />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <DateField name="endMaturity" label="Vencimento até" />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <SelectField options={optionsTypeProvider} name="typeProvider" label="Tipo de Prestador" />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <SelectField options={optionsCategory} name="category" label="Tipo de Requisição" />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <TextField name="idTotvs" label="Numero do pedido" />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <TextField name="idRequest" label="ID da requisição" />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <SelectField options={optionsAllocation} name="allocation" label="Alocação da Requisição" />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <SelectField options={optionsBU} name="buId" label="Local da Requisição" />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <TextField name="projectIdERP" label="ID do Projeto da Requisição" />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <SelectField options={optionsClients} name="clientId" label="Cliente da Requisição" />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <SelectField options={optionsTiming} name="timing" label="Timing" />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <SelectField options={optionsStatus} name="statusRequest" label="Status da Requisição" />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <SelectField options={userOptions} name="requesterId" label="Requisitante" />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <SelectField options={optionsProduct} name="productId" label="Produto" />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <SelectField options={optionsSuppliers} name="supplierId" label="Fornecedor / Freelancer" />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <MaskedField mask='99.999.999/9999-99' name="supplierIdCnpj" label="CNPJ do Fornecedor ou Freelancer" />
          </Col>
        </Row>
        <Form.Item className='mt-1'>
          <Space size='small'>
            <Button
                onClick={handleSubmit}
                type='primary'
            >
              Filtrar
            </Button>
            <Button
                onClick={onClickResetFilters}
            >
              Limpar
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default FiltersForm
