import React, { useEffect, useMemo, useState } from "react";
import {Badge, List, Row, Tooltip} from "antd";
import { IoIosArrowDown, IoIosArrowBack } from "react-icons/io";
import {TApprovalHierarchyUser, TPurchaseOrder} from "repositories";
import { formatCurrency } from "utils/helpers";
import { useAuth } from "hooks";
import {Container, Header, Content} from "./styles";
import {BiCommentDetail} from "react-icons/bi";

type TApprover = Pick<TApprovalHierarchyUser, "userName"> & {
  minimumValue: string;
  isApproved: boolean;
  isCurrent: boolean;
  approveAnswer: any;
};

type TProps = {
  order: TPurchaseOrder;
};

const UserHierarchy = ({ order }: TProps) => {
  const { approvers } = order

  const [isOpen, setIsOpen] = useState(true);

  const { userData } = useAuth();

  const onSort = (list: TApprovalHierarchyUser[] | undefined) => {
    if ( list === undefined) return [];
    if ( !list.length) return [];

    return list.sort((old, after) => {
      if (old.approveSequence > after.approveSequence) return 1;
      if (old.approveSequence < after.approveSequence) return -1;

      return 0;
    });
  };

  const formattedApprovers = useMemo(() => {
    if (isOpen) {
      const ordenedApprovers = onSort(approvers);
      const currentUser = ordenedApprovers.find(
        (find) => find.userEmail === userData?.userEmail
      );
      //if (!currentUser) return [] as TApprover[];

      return ordenedApprovers.map((approver) => {
        const isCurrent = currentUser?.userId === approver.userId;
        const formattedMinimumValue = formatCurrency(
          approver.minimumValue,
          false
        );
        return {
          userName: approver.userName,
          minimumValue: `Valor mínimo de ${formattedMinimumValue}`,
          isApproved: approver.approveAnswer?.answer === "APPROVED" ? true : false,
          isCurrent,
          approveAnswer: approver.approveAnswer,
        } as TApprover;
      });
    }

    return [] as TApprover[];
  }, [approvers, userData, isOpen]);

  const handleOpen = () => setIsOpen(!isOpen);

  const StatusApprover = ({ approver }: any) => {
    switch (approver) {
      case "APPROVED":
        return <Badge status="success" />;
      case "REFUSED":
        return <Badge status="error" />;
      default:
        return <Badge status="warning" />;
    }
  };

  return (
    <Container>
      <Header onClick={handleOpen}>
        <span>Aprovadores</span>
        {isOpen ? <IoIosArrowDown /> : <IoIosArrowBack />}
      </Header>
      {isOpen && (
        <Content>
          <List size="small">
            {formattedApprovers.map((approver) => (
              <List.Item key={approver.userName}>
                <List.Item.Meta
                  title={
                    <div>
                      <Row>
                        <span>{approver.userName}</span> - {"  "}
                        <span>{approver.minimumValue}</span>
                        {approver.approveAnswer?.observation && (
                            <Tooltip title={approver.approveAnswer?.observation}>
                              <BiCommentDetail />
                            </Tooltip>
                        )}
                      </Row>

                    </div>

                  }
                />

                <StatusApprover approver={approver?.approveAnswer?.answer} />

                {/* {approver.isApproved && <Badge status="success" />}
                {(!approver.isApproved || approver.isCurrent) && (
                  <Badge status="warning" />
                )} */}
              </List.Item>
            ))}
          </List>
        </Content>
      )}
    </Container>
  );
};

export default UserHierarchy;
