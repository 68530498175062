import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 5px;
`
export const LineWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  margin-bottom: 8px;
`;

export  const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 12px;
  }

  button {
    padding: 0;
    margin: 0;
  }

  button svg {
    width: 16px;
    height: 16px;
  }
`

export const Content = styled.div`
  div {
    font-size: 12px;
  }
`
