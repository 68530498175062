import React, { useEffect, useMemo, useState } from "react";
import { Button, Dropdown, Menu } from "antd";
import { BiChevronDown } from "react-icons/bi";
import { TGroupMemberChat, TMemberChat, TPurchaseOrder } from "repositories";
import type { ItemType } from "antd/lib/menu/hooks/useItems";
import Chat from "pages/Components/Chat";
import { Container, Flex } from "./styles";
import { useAuth } from "hooks";
import { AiOutlineBell } from "react-icons/ai";

type TProps = {
  purchaseOrderId?: number;
  groupMembers: TGroupMemberChat[];
  setGroupMembers: React.Dispatch<TGroupMemberChat[]>;
  order: any;
  updateList: Function;
};

const ChatRequisition = (props: TProps) => {
  const { purchaseOrderId, groupMembers, setGroupMembers, order, updateList } =
    props;
  const [isVisible, setIsVisible] = useState(false);
  const [talkTo, setTalkTo] = useState<TMemberChat>({} as TMemberChat);
  const [menuOptions, setMenuOptions] = useState<ItemType[]>([]);
  const [newMessage, setNewMessage] = useState(false);
  const [forceRender, setForceRender] = useState(false);

  const { userData } = useAuth();

  useEffect(() => {
    // console.log("userData:", userData);
    const getChats = () => {
      if (!purchaseOrderId) return;

      if (!order) return;
      const chats = order.chat;

      const mappedChats = groupMembers.map((groupChat) => {
        const mappedMembers = groupChat.members.map((member) => {
          const userIds = [member.userToId];
          const chat = chats.find((item: any) =>
            item.users.some((user: any) => userIds.includes(user.id))
          );

          if (!chat?.id) return { ...member, chatId: 0 };

          return {
            ...member,
            chatId: chat.id || 0,
            messagesNotViewed:
              member.userFromId !== member.userToId
                ? chat.messagesNotViewed
                : 0,
          };
        });

        return {
          ...groupChat,
          members: mappedMembers,
        };
      });

      const filterGroupOptions = mappedChats.filter((groupMember) =>
        groupMember.members.some((member) => member.userToId)
      );

      const mappedGroupOptions = filterGroupOptions.map((groupMember) => {
        const { title, members } = groupMember;
        return {
          label: title,
          type: "group",
          key: title,
          children: members.map((member) => ({
            danger: !!member.messagesNotViewed,
            disabled: userData.userId === member.userToId || !order.isInRequest,
            label: member.userToName,
            key: member.key,
            onClick: onClickTalkTo(member),
          })),
        } as ItemType;
      });
      setMenuOptions(mappedGroupOptions);
      chats.forEach((chat: any) => {
        if (chat.messagesNotViewed > 0) {
          setNewMessage(true);
        }
      });
    };

    if (purchaseOrderId && groupMembers.length) getChats();
  }, [purchaseOrderId, groupMembers]);

  useEffect(() => {
    const updateChatIdAfterCreateChat = () => {
      const mappedGroupMembers = groupMembers.map((groupMember) => ({
        ...groupMember,
        members: groupMember.members.map((member) => {
          if (member.key !== talkTo.key) return member;

          return {
            ...member,
            chatId: talkTo.chatId || 0,
            messagesNotViewed: 0,
          };
        }),
      }));

      setGroupMembers(mappedGroupMembers);
    };

    if (talkTo.chatId) updateChatIdAfterCreateChat();
  }, [talkTo]);

  const onClickTalkTo = (member: TMemberChat) => () => {
    setNewMessage(false);
    setIsVisible(true);
    setTalkTo(member);
    updateList();
  };

  const setTalkToProp = (member: any) => {
    setTalkTo({ ...member });
  };

  const menu = useMemo(
    () => <Menu items={menuOptions} />,
    [menuOptions, purchaseOrderId]
  );

  const onClickPreventDefault =
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => () => {
      e.preventDefault();
    };

  return (
    <Container>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button type="link" onClick={onClickPreventDefault}>
          <Flex style={{ alignItems: "center" }}>
            {newMessage ? (
              <img
                src="/bell-solid.svg"
                alt=""
                width="50px"
                style={{ marginTop: "-5px" }}
              />
            ) : (
              <></>
            )}
            <span>Questionar</span>
            <BiChevronDown />
          </Flex>
        </Button>
      </Dropdown>
      <Chat
        isVisible={isVisible}
        member={talkTo}
        setMember={setTalkToProp}
        purchaseOrderId={purchaseOrderId}
        setIsVisible={setIsVisible}
        order={order}
      />
    </Container>
  );
};

export default ChatRequisition;
